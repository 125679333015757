import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SEO from "../../components/seo";
import TrailTitle from "../../components/TrailTitle";

const delay = keyframes`
  0% {
    opacity: 0;
    height : 0;
  }

  100% {
    opacity: 0;
    height : initial;
  }
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const DelayFade = styled(Col)`
  animation: ${delay} ${(props) => props.order * 0.5}s,
    ${fade} 0.5s ${(props) => props.order * 0.5}s;
`;

const categories = [
  "STRUKTURAL",
  "KOMITE",
  "SATUAN PEMERIKSA INTERNAL",
  // 'UNIT PELAYANAN',
  // 'UNIT KERJA',
  // 'TIM',
  "BIDANG PELAYANAN",
  "BIDANG PENUNJANG",
  "BIDANG PENDIDIKAN",
  "BAGIAN UMUM",
  "BIDANG KEUANGAN",
];

const OrganizationPage = ({ data, location }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!open);

    return () => {
      setOpen(!open);
    };
  }, []);

  const services = data.doctors.edges.reduce(
    (
      arr,
      {
        node: {
          service: {
            value: { display },
          },
        },
      }
    ) => (arr.includes(display) ? arr : [...arr, display]),
    []
  );

  return (
    <>
      <SEO title='Struktur Organisasi' />
      <Img fluid={data.cover.childImageSharp.fluid} />
      <Row
        css={{
          minHeight: "75vh",
          backgroundImage: `url(${data.bg.childImageSharp.fixed.src})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
        }}
        className='pt-5'>
        <Col
          xs={"12"}
          sm={"5"}
          className='d-flex flex-column align-items-center align-content-center'>
          <TrailTitle open={open} className='pb-2 mb-5'>
            <div>Struktur</div>
            <div>Organisasi</div>
          </TrailTitle>
        </Col>
        <Col xs={"12"} sm={{ span: "8", offset: 2 }}>
          {categories.map((c) => (
            <div className='mb-2'>
              <h5>{c}</h5>
              <Row className='mb-5'>
                {data.members.edges
                  .filter(({ node: { category } }) => category?.value === c)
                  .map(({ node: { name, title, photo, cockpitId } }, idx) => (
                    <DelayFade xs={"6"} sm={"3"} key={cockpitId} order={idx}>
                      <Img
                        fluid={photo.value.childImageSharp.fluid}
                        className='mb-2 rounded-lg'
                      />
                      <div className='d-flex flex-column justify-content-center align-items-center align-content-center mb-3'>
                        <strong
                          css={{
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}>
                          {title.value}
                        </strong>
                        <div css={{ textAlign: "center" }}>{name.value}</div>
                      </div>
                    </DelayFade>
                  ))}
              </Row>
            </div>
          ))}
          <h4 class='font-weight-bold'>
            Juga didukung oleh para dokter profesional dan kompeten:
          </h4>
          <div>
            {services.map((s, idx) => (
              <Row key={idx}>
                <Col sm={"3"}>
                  <strong>Dokter {s}: </strong>
                </Col>
                <Col>
                  <ol>
                    {data.doctors.edges
                      .filter(
                        ({
                          node: {
                            service: {
                              value: { display },
                            },
                          },
                        }) => display === s
                      )
                      .map(({ node: { name: { value } } }, idx) => (
                        <li key={idx}>{value}</li>
                      ))}
                  </ol>
                </Col>
              </Row>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  {
    members: allCockpitMembers(filter: { display: { value: { eq: true } } }) {
      edges {
        node {
          cockpitId
          name {
            value
          }
          title {
            value
          }
          category {
            value
          }
          photo {
            value {
              childImageSharp {
                fluid(toFormat: WEBP) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    doctors: allCockpitDoctors(filter: { display: { value: { eq: true } } }) {
      edges {
        node {
          service {
            value {
              display
            }
          }
          name {
            value
          }
        }
      }
    }
    cover: file(name: { eq: "bg-organisasi" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: WEBP) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default OrganizationPage;
